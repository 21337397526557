/**tablet**/
@media (max-width: 992px) {

  .garanties {
    .activite-icon {
      flex-basis: 50%;
    }
  }
  .activites {
    .activite-icon {
      flex-basis: 50%;
    }
  }


}