.navbar {
  background-color: #fdef75;


  .navbar-brand {

    img {
      max-height: 80px;
      padding-right: 50px;
    }
  }

  li a.nav-link {
    color: #000000;
    font-family: Oswald, sans-serif;
    font-weight: 200;
    font-size: 25px;
    text-transform: uppercase;
    //padding-left: 15px;
  }

  .navbar-nav {
    li:hover a,
    li.current-menu-item a {
      //content: "";
      //width: 100%;
      //height: 4px;
      //display: block;
      //position: relative;
      //left: 0;
      //background-color: #000;
      border-bottom: 4px solid #000;
      padding-bottom: 4px;
    }

  }


  .languages {
    font-family: Oswald, sans-serif;
    font-weight: 200;
    font-size: 15px;
    color: #000;
    //background-color: #0925e1;
    min-width: 80px;
    text-align: center;
    position: relative;
    height: 30px;
    padding: 0px 10px;
  }

  .lan-icons {
    padding: 0;
    margin: 0;
    //background-color: #00b3ee;
    text-align: center;
    position: relative;
    height: 30px;
    top: 5px;
    left: 0px;


    li {
      display: inline;
      list-style: none;
      float: left;
      img {
        max-width: 40px;
        padding-left: 5px;
      }
    }
  }


}



//.menu-header-menu {
//  padding: 0;
//  margin: 0;
//  float: left;
//
//
//  li {
//    list-style: none;
//    display: inline;
//    font-family: Oswald, sans-serif;
//    font-weight: 200;
//    font-size: 25px;
//    text-transform: uppercase;
//    padding-left: 15px;
//  }
//
//  li a {
//    color: #000 !important;
//  }
//
//  li:hover a,
//  li.current-menu-item a {
//    border-bottom: 4px solid #000;
//    padding-bottom: 8px;
//  }
//}