/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  //margin-bottom: 60px;
}
//.footer {
//  position: absolute;
//  bottom: 0;
//  width: 100%;
//  /* Set the fixed height of the footer here */
//  height: 60px;
//  line-height: 60px; /* Vertically center the text there */
//  background-color: #f5f5f5;
//}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

//body > .container {
//  padding: 100px 15px 0;
//}

//.footer > .container {
//  padding-right: 15px;
//  padding-left: 15px;
//}

//code {
//  font-size: 80%;
//}

.footer {
  background-color: rgba(255, 230, 0, 0.05);
  padding: 70px;


  h3 {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: lighter;
  }

  .partenaires {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: inline;
    }
  }

}