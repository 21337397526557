//Place here your custom scss code
//@import "../bootstrap-scss/bootstrap";
//@import "../fontawesome-scss/fontawesome";
@import "fonts";
@import "menu";
//@import "default";
@import "footer";
/*@import "nav";

@import "article";
@import "newsletter";
@import "connexion";
@import "pages";
@import "utiles";*/


body {
  text-rendering: optimizeLegibility;
  font-family: Oswald, sans-serif;
}

body > .wrapper {
  padding: 100px 0px 0;
  background-color: #FFFFFF;
  max-width: 100%;
  width: 100%;
}

section {
  //padding: 50px;
}

.video-intro {
  //iframe {
  //  height: calc(100vh - 100px);
  //  width: 100%;
  //}

  .col-lg-12 {
    padding-right: 0px;
    padding-left: 0px;
  }

}

.tv {
  position: relative;
  top: 0px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
}
.tv .screen {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: opacity .5s;
}
.tv .screen.active {
  opacity: 1;
}




.text-inro {
  padding: 40px 0;
  font-family: Oswald, sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: #000;
  text-align: center;
}


.activites {
  padding: 0 0 50px 0 ;
  .ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;

  }
  .activite-icon {
    width: 18%;
    margin-top: 20px;
    list-style: none;
    display: block;
    //background-color: #7a43b6;
    text-align: center;
    font-family: Oswald, sans-serif;

    img {
      display: block;
      width: 135px;
      height: 135px;
      margin: 0 auto;
      border: 1px dashed #000;
      padding: 15px;
      border-radius: 50%;
      margin-bottom: 15px;

    }

    h3 {
      font-size: 22px;
      -ms-transform: rotate(-7deg); /* IE 9 */
      -webkit-transform: rotate(-7deg); /* Safari */
      transform: rotate(-7deg);
    }
    a {
      color: #000;
    }

  }

}

.ambiances {

  padding: 20px 0 50px 0 ;

  background-color: rgba(118, 203, 255, 0.14);

  .carousel-indicators {
    position: relative;
    //bottom: -5px;
    padding: 25px;

    li {
      border-radius: 50%;
      color: #000;
      background-color: #000;
      width: 15px;
      height: 15px;

    }
    .active {
      border: 1px solid #000;
      background-color: transparent;
    }
  }



  .carousel-item {
    //background-color: #adbbd2;
    margin-bottom: 25px;


    h3 {
      font-family: Oswald, sans-serif;
      font-weight: 100;
      font-size: 19px;
      text-transform: uppercase;
      background-color: #FFFFFF;
      border-bottom: 2px solid #000;
      padding: 20px 20px 20px 80px;

    }

    h3:before {
      content: "";
      background-color: #fff;
      width: 5px;
      height: 62px;
      display: block;
      //border: 1px solid #000;
      padding: 0;
      position: absolute;
      bottom: 10px;
      left:85px;
      border-left: 1px dashed #000;
    }

    .service-icon {
      position: absolute;
      bottom: 15px;
      left: 25px;
      width: 50px;
      height: 50px;

    }
  }
}

.monitoring {

  .col-lg-6{
    padding-left: 45px;
  }

  .img {
    //padding: 0px;
    background-image: url("/images/spaceco.jpg");
    background-size: cover;
    min-height: 300px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 23px;
    font-weight: normal;
  }

  p {
    font-weight: 100;
    font-size: 19px;
    padding: 40px 0;
  }

  .price {
    color: #ff0000;
    font-size: 25px;
    font-weight: 100;
    small {
      font-weight: 100;
    }
  }

  .barre {
    font-size: 19px;
    font-weight: lighter;
    text-decoration: line-through;
  }

  .btn {
    margin: 60px 0;
    min-width: 250px;
  }


}

.garanties {

  padding: 20px 0 60px 0;

  .ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;

  }
  .activite-icon {
    width: 18%;
    margin-top: 20px;
    list-style: none;
    display: block;
    //background-color: #7a43b6;
    text-align: center;
    font-family: Oswald, sans-serif;
    flex-basis: 18%;

    img {
      display: block;
      width: 135px;
      height: 135px;
      margin: 0 auto;
      //border: 1px dashed #000;
      padding: 15px;
      border-radius: 50%;
      margin-bottom: 15px;
      background-color: #ffe700;

    }
    h3 {
      font-size: 19px;
      font-weight: lighter;
    }
    a {
      color: #000;
    }
  }
}

.team {
  padding: 0 ;

  .carousel-item {
    img {
      width: 100%;
      height: 500px;
    }
  }

  .carousel-caption {
    color: #000;

  }

  h4, p {
    font-weight: lighter;
  }

  h3 {
    font-size: 46px;
  }
  h4 {
    font-size: 34px;
  }
  p {
    font-size: 23px;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: auto;
    padding-left: 0;
    margin-right: 5%;
    margin-left: 5%;

    li  {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      background-image: url("/images/spaceco.jpg");
      background-size: cover;
      margin-right: 15px;
    }
    li.active {
      border: 2px solid #FFF;
    }
  }


}

.testimonial {
  background-color: #fdef75;
  padding: 50px;

  .carousel {
    .carousel-item {

      .container {
        color: #000;
        font-weight: lighter;
        font-size: 19px;
        text-align: center;

        h4 {
          font-weight: lighter;
          text-transform: uppercase;
          font-size: 19px;

        }
      }

      img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
      }
    }

    .carousel-indicators {
      position: relative;

      li  {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background-color: #000;
      }
      li.active {
        border: 2px solid #747474;
        background-color: transparent;
      }
    }
  }

}

.contacts {
  font-weight: lighter;


  b {
    display: block;
  }

  .col-md-5 {
    //padding-left: 5%;
    padding: 60px 50px;
  }
  .col-md-7 {
    padding: 0;
    margin: 0;
    background-color: #000000;
    //margin-top: -16px;

  }

  .map-responsive{
    overflow:hidden;
    //padding-bottom:56.25%;
    position:relative;
    height:100%;
  }
  .map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }

}




ul.smedia {
  padding: 30px 0;
  margin: 0;

  li {
    display: inline;
    list-style: none;
    margin-right: 10px;

    i {
      font-size: 26px;
      border-radius: 50%;
      border: 2px solid #000000;
      width: 40px;
      height: 40px;
      padding: 6px;
      color: #000000;
    }
    .fa-facebook {
      padding: 6px 10px;
    }
    .fa-instagram {
      padding: 6px 7px;
    }
  }

}
h2 {
  font-family: 'Lobster', cursive;
  font-size: 43px;
  padding: 35px 0;
}
h2:before {
  content: url("/images/h2.png");
  position: relative;
  top: 15px;
  right: 15px;

}

.btn-primary {
  border-radius: 0px;
  padding: 18px 50px;
  text-transform: uppercase;
  font-size: 16px;
  background-color: #ffe700;
  color: #000;
  font-weight: bold;
  font-family: Oswald, sans-serif;
  border: 0px;
  min-width: 250px;
}
.btn-primary:hover {
  background-color: #000000;
  color: #ffe700;
}

@import "media1200";
@import "media992";
@import "media576";

