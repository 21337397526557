/**mobile**/

@media (max-width: 576px) {
  .garanties {
    .activite-icon {
      flex-basis: 100%;
    }
  }
  .activites {
    .activite-icon {
      flex-basis: 100%;
    }
  }
}